/* App.js */
.App {
  opacity: 1;
  transition: 1s opacity;
}

.App.fade-out {
  opacity: 0;
  transition: none;
}

#canvas {
  min-height: 100vh;
  position: relative;
}

/* Header.js */
.portfolio-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 18px;
  color: #FBFFFE;
  background: #001514;
  padding: 10px 25px;
}

.portfolio-header h1 {
  margin: 0;
  font-size: 2em;
}

.portfolio-header p {
  margin: 5px 0;
  font-size: 14px;
  font-weight: 500;
  color: #b9b9b9
}

.portfolio-header .links {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.portfolio-header .links a {
  text-decoration: initial;
  color: #b9b9b9
}

.portfolio-header .links a.active {
  color: #E6AF2E;
}

.portfolio-header .links span {
  margin: 5px 15px;
  font-weight: 400;
  font-size: 16px;
}

.portfolio-header .links span:hover {
  color: #FBFFFE;
  cursor: pointer;
}

/* Home.js */
.home {
  width: 50%;
  font-weight: 400;
  font-size: 16px;
}

.title-project a {
  color: #E6AF2E;
  font-weight: 500;
}

.title-project a:hover {
  text-decoration: none;
}

.highlight-image {
  text-align: center;
}

.highlight-image img {
  width: 1000px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.highlighted-projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.highlighted-projects img {
  height: 85px;
  width: auto;
  border-radius: 0.5rem;
  margin-bottom: 10px;
}

/* Content.js */
.content {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 125px;
  overflow-x: hidden;
}

.project {
  display: flex;
  width: 100%;
  justify-content: center;
}

.project .card {
  width: 60%;
  /* background-color: #FBFFFE; */
  border-radius: 0.5rem;
  box-shadow: 0px 1px 3px rgb(0 21 20 / 20%);
}

.project .card h1,
.project .card h2, 
.project .card h3,
.project .card h4 {
  color: #E6AF2E;
  text-align: center;
}

.project .card p {
  font-size: 18px;
  font-weight: 400;
  margin: 20px 15%;
  text-align: left;
  white-space: pre-line;
}

.project .card .info p {
  margin-top: 0;
  margin-bottom: 0;
}

.project .card a {
  color: #E6AF2E
}

.project .card a:hover {
  text-decoration: none;
}

.project .card .list {
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  margin: 20px 15%;
}

.project .card .level-designs {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.project .card .level-designs img {
  float: left;
  width:  325px;
  height: 200px;
  object-fit: cover;
  padding: 10px;
}

.project .card .download-link {
  text-align: center;
}

.project .card .download-link p {
  margin: 5px 0;
  text-align: center;
}

.project .card iframe {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 0.5rem;
  margin-top: 15px;
}

.project .card .download-link a img {
  width: 150px;
}

.project > *:last-child {
  padding-bottom: 10px;
}

/* Bio.js */
.project .card .bio {
  display: flex;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  padding: 15px;
}

.project .card .bio .profile-picture {
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 25px;
}

.project .card .bio img {
  box-shadow: 2px 2px 2px 0px rgb(107 5 4 / 25%);
}

/* .project .card .bio div {
  padding: 15px;
} */

.project .card .bio p {
  font-size: 16px;
  margin: 15px 0;
}

.profile-picture img {
  height: 300px;
  border-radius: 0.5rem;
}

/* Contact.js */
.contact {
  width: 65%;
  margin: auto;
  background: #001514;
  padding: 20px 75px;
}

.contact h3 {
  text-align: center;
}

.resume {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.resume img {
  height: 50px;
  margin: 0 5px;
}

.contact form label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  /* color: #FBFFFE; */
  font-size: 14px;
  font-weight: 400;
}

.contact input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #FBFFFE;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.contact input[type="submit"] {
  background: black;
  color: #FBFFFE;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
}

.contact input[type="submit"]:hover {
  background: #424242;
}

.contact input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

.contact input[type="submit"]:hover {
  transition: 0.3s all;
}

.contact input[type="submit"] {
  cursor: pointer;
  outline: none;
}

.contact textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #FBFFFE;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
  resize: vertical;
  min-height: 100px;
  max-height: 250px;
}

.contact form span {
  text-align: left;
  color: #bf1650;
}

.contact form span::before {
  display: inline;
  content: "⚠ ";
}

.contact .mail-success {
  color: green;
}

/* Preview.js */
.preview {
  margin: 15px 0;
}

.preview .image img {
  width: 800px;
}

.preview h4 {
  text-align: center;
}

.preview .top-controls {
  display: flex;
  justify-content: flex-end;
}

.preview .top-controls span {
  margin: 5px;
  font-size: 24px;
  font-weight: 500;
}

.preview .bottom-controls {
  text-align: center;
}

.preview .bottom-controls img {
  margin: 20px 15px;
  width: 32px;
}

/* Thumbnail.js */
.thumb {
  font-weight: 500;
}

/* Footer.js */
.footer {
  display: flex;
  width: 100%;
  height: 100px;
  flex-direction: column;
  text-align: center;
  background: #001514;
  position: absolute;
  bottom: 0;
}

.footer .social {
  display: flex;
  justify-content: center;
}

.footer .social div {
  height: 25px;
  margin: 0 5px;
}

.footer .social div img {
  height: 100%;
}

.footer .social .linkedin {
  background: white;
  border-radius: 0.25rem;
}

/* Media Queries (tablet) */
@media only screen and (max-width: 1200px) {
  .project .card {
    width: 80%;
  }

  .highlight-image img {
    width: 800px;
  }
}

/* Media Queries (mobile) */

@media only screen and (max-width: 540px) {
  .portfolio-header {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .portfolio-header p {
    text-align: center;
  }

  .portfolio-header .links span {
    margin: 5px 10px;
  }

  .home {
    width: 80%;
  }

  .project .card p, .project .card .list {
    font-size: 16px;
    margin: 20px 5%;
  }

  .project .card .highlighted-projects {
    flex-direction: column;
    align-items: center;
    justify-content: initial;
  }

  .highlight-image img {
    width: 515px;
  }

  .project .card .highlighted-projects img {
    height: initial;
    /* width: 50%; */
    margin-bottom: 10px;
  }

  .project .card .level-designs img {
    width:  150px;
    height: 100px;
    padding: 5px;
  }

  .project .card .bio {
    flex-direction: column;
    align-items: center;
  }

  .project .card .bio .profile-picture {
    padding: 5px;
  }

  .project .card a img {
    width: 150px;
  }

  .project .card .resume img {
    width: auto;
  }

  .contact {
    width: 85%;
    padding: 5px 15px;
  }

  .preview .image img {
    width: 400px;
  }
  
  .thumb {
    font-size: 14px;
  }
}
